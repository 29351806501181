import { Typography } from "@mui/material";

import Label from "../Label";
import * as S from "./styles";

type SliderProps = {
  id: string;
  label: string;
  value: number | number[];
  min?: number;
  max?: number;
  valueMask?: string;
  onChangeControl: (id: string, value: number | number[]) => void;
};

const SliderInput = ({
  id,
  label,
  value,
  min = 0,
  max = 100,
  valueMask,
  onChangeControl,
}: SliderProps) => {
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    onChangeControl(id, newValue);
  };

  return (
    <div>
      <Label htmlFor={id}>{label}</Label>
      <Typography variant="h5" color="primary.contrastText">
        {valueMask?.replace("{x}", value.toString()) || value}
      </Typography>
      <S.CustomSlider
        min={min}
        max={max}
        defaultValue={value}
        onChange={handleSliderChange}
        color="secondary"
      />
    </div>
  );
};

export default SliderInput;
