import { styled } from "@mui/system";
import { TextField } from "@mui/material";

export const CustomInput = styled(TextField)(
  ({ theme }) => `
  & .MuiInputBase-input{
    border-radius: 4px;
    background-color: white;
    padding: 10px 12px;
    color: ${theme.palette.primary.main};
  }
  
  `
);
