import { useState } from "react";
import { Typography, Container, Grid, Box, Button } from "@mui/material";

import logo from "../../assets/gofore_logo_orange.svg";
import Summary from "./components/Summary";
import Controls from "./components/Controls";

const Homepage = () => {
  const initValues = {
    totalEmployees: 29,
    workingDays: 22,
    avgCustomer: 97,
    utilization: 56,
    othersCosts: 9000,
  };
  const [controlsValue, setControlsValue] = useState(initValues);

  const handleControls = (id: string, value: string | number | number[]) => {
    const numberValue = Number(value);
    setControlsValue({ ...controlsValue, [id]: numberValue });
  };

  const handleReset = () => {
    setControlsValue(initValues);
  };

  return (
    <Container maxWidth="lg">
      <Box my={8}>
        <img src={logo} width="94px" alt="gofore logo" />
        <Typography variant="h4" color="primary.contrastText">
          Germany Profit Estimate Tool
        </Typography>
      </Box>
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={12}
          md={5}
          display="flex"
          flexDirection="column"
          gap={3}
          mb={{ xs: 3, md: 0 }}
        >
          <Controls
            controlsValue={controlsValue}
            handleControls={handleControls}
          />
          <Button color="secondary" variant="contained" onClick={handleReset}>
            Reset Params
          </Button>
        </Grid>
        <Grid item xs={12} md={4.5} alignSelf="center">
          <Summary controlsValue={controlsValue} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Homepage;
