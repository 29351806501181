import { ChangeEvent, ReactNode } from "react";

import Label from "../Label";
import CurrencyInput from "./CurrencyInput";
import * as S from "./styles";

type TextInputProps = {
  id: string;
  label: string;
  value: string;
  variation?: "onlyNumbers" | "currency";
  helperText?: ReactNode;
  onChangeControl: (id: string, value: string) => void;
};

const TextInput = ({
  id,
  label,
  value,
  variation,
  onChangeControl,
  helperText,
}: TextInputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (variation === "onlyNumbers") {
      const numbers = e.target.value.replace(/[^0-9]/g, "");
      onChangeControl(id, numbers);
      return;
    }
    onChangeControl(id, e.target.value);
  };

  return (
    <div>
      <Label htmlFor={id} helperText={helperText}>
        {label}
      </Label>
      {variation === "currency" ? (
        <S.CustomInput
          id={id}
          value={value}
          margin="dense"
          onChange={handleChange}
          fullWidth
          InputProps={{
            inputComponent: CurrencyInput,
          }}
        />
      ) : (
        <S.CustomInput
          id={id}
          value={value}
          margin="dense"
          onChange={handleChange}
          fullWidth
        />
      )}
    </div>
  );
};

export default TextInput;
