import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0f3d51",
    },
    secondary: {
      main: "#f7673b",
    },
    success: {
      main: "#00A858",
    },
    error: {
      main: "#F03540",
    },
  },
  typography: {
    fontFamily: ["'Open Sans'", "sans-serif"].join(","),
    h4: {
      fontSize: 32,
      fontWeight: 400,
    },
    h6: {
      fontWeight: 400,
    },
    h3: {
      fontWeight: 500,
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#fee",
          color: "#0f3d51",
        },
      },
    },
  },
});

export default theme;
