import { useState, useEffect } from "react";
import { Card, Typography, Box, Button } from "@mui/material";
import MovingIcon from "@mui/icons-material/Moving";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

import { fixedVariables } from "../../../../constants";
import FixedCollapse from "./FixedCollapse";
import * as S from "./styles";

type SummaryProps = {
  controlsValue: {
    totalEmployees: number;
    workingDays: number;
    avgCustomer: number;
    utilization: number;
    othersCosts: number;
  };
};

const Summary = ({ controlsValue }: SummaryProps) => {
  const [sumTotal, setSumTotal] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(+0.201);
  const sumTotalText =
    "€" +
    (sumTotal || 0).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  const percentageText =
    ((percentage || 0) * 100).toFixed(2).toString().replace(".", ",") + "%";
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleTotal = () => {
    const fte =
      controlsValue.totalEmployees -
      fixedVariables.partTimeFte.value -
      fixedVariables.absenceFte.value;

    const totalPersonCost =
      controlsValue.totalEmployees *
      fixedVariables.avgBusinessCostPerPeson.value;

    const crewRevenue =
      controlsValue.avgCustomer *
      (controlsValue.utilization / 100) *
      controlsValue.workingDays *
      fixedVariables.hoursPerDay.value *
      fte;

    const totalRevenue = crewRevenue + fixedVariables.subRevenue.value;
    const subconractingProfit =
      fixedVariables.subRevenue.value * fixedVariables.subProfitMargin.value;

    const profit =
      crewRevenue +
      subconractingProfit -
      totalPersonCost -
      controlsValue.othersCosts;

    const profitPercentage = profit / totalRevenue;

    setSumTotal(profit);
    setPercentage(profitPercentage);
  };

  useEffect(() => handleTotal());

  return (
    <Card>
      <Box p={5} textAlign="center">
        <Typography variant="h6">Estimated month profit</Typography>
        <Typography variant="h3" color="primary" my={4}>
          {sumTotalText}
        </Typography>
        <S.Badge
          label={percentageText}
          color={percentage > 0 ? "success" : "error"}
          icon={percentage > 0 ? <MovingIcon /> : <TrendingDownIcon />}
        />
      </Box>
      <S.Action disableSpacing>
        <Button size="small" color="primary" onClick={handleExpandClick}>
          {expanded ? "Hide" : "Show"} fixed params
          <S.ExpandIcon $expanded={expanded} />
        </Button>
      </S.Action>
      <S.CollapseContent in={expanded} timeout="auto" unmountOnExit>
        <FixedCollapse />
      </S.CollapseContent>
    </Card>
  );
};

export default Summary;
