import { ThemeProvider } from "@mui/material/styles";
import { useIsAuthenticated } from "@azure/msal-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "./pages/homepage";
import Login from "./pages/Login";
import theme from "./theme";

function App() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? <Homepage /> : <Login />}
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
