import { Chip, CardActions, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";

export const Badge = styled(Chip)`
  font-size: 14px;
  border-radius: 4px;

  & .MuiChip-icon {
    font-size: 20px;
  }
`;

export const Action = styled(CardActions)(
  ({ theme }) => `
border-top: 1px solid ${theme.palette.grey[200]};
justify-content: center;
`
);

export const ExpandIcon = styled(ExpandMoreIcon)(
  ({ $expanded }) => `
  transform: ${!$expanded ? "rotate(0deg)" : "rotate(180deg)"};
  transition: transform 0.2s;
`
);

export const CollapseContent = styled(Collapse)`
  & .MuiCardContent-root {
    padding-top: 0px;
  }
`;
