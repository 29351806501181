import { useMsal } from "@azure/msal-react";
import { Button, Typography, Card, Container, Box } from "@mui/material";

import { loginRequest } from "../../authConfig";
import logo from "../../assets/gofore_logo_orange.svg";

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Card>
          <Box p={6} textAlign="center">
            <img src={logo} width="94px" alt="gofore logo" />
            <Typography variant="h6" color="primary" my={3}>
              Germany Profit Estimate Tool
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleLogin("popup")}
            >
              Login with Azure AD
            </Button>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default Login;
