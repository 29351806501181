export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENTID!,
    authority: process.env.REACT_APP_AZURE_AUTHORITY!,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECTURI!,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
