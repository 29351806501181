import { Grid, Typography } from "@mui/material";

import TextInput from "../../../../components/Inputs/TextInput";
import SliderInput from "../../../../components/Inputs/SliderInput";
import { workingDaysForecast } from "../../../../constants";

type ControlsProps = {
  handleControls: (id: string, value: string | number | number[]) => void;
  controlsValue: {
    totalEmployees: number;
    workingDays: number;
    avgCustomer: number;
    utilization: number;
    othersCosts: number;
  };
};

const Controls = ({ controlsValue, handleControls }: ControlsProps) => {
  const workinDaysHelpText = (
    <>
      <Typography variant="caption">
        <b>2023 Forecast (days)</b>
      </Typography>
      <Grid container gap={1}>
        <Grid item xs>
          {workingDaysForecast.slice(0, 6).map((item) => (
            <div key={item.month}>
              <Typography variant="caption">
                {item.month}: {item.value}
              </Typography>
              <br />
            </div>
          ))}
        </Grid>
        <Grid item xs>
          {workingDaysForecast.slice(6, 12).map((item) => (
            <div key={item.month}>
              <Typography variant="caption" key={item.month}>
                {item.month}: {item.value}
              </Typography>
              <br />
            </div>
          ))}
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Grid container justifyContent="space-between" gap={2}>
        <Grid item xs>
          <TextInput
            id="totalEmployees"
            label="Total no. employees​"
            value={controlsValue.totalEmployees.toString()}
            variation="onlyNumbers"
            onChangeControl={handleControls}
          />
        </Grid>
        <Grid item xs>
          <TextInput
            id="workingDays"
            label="Working days"
            value={controlsValue.workingDays.toString()}
            variation="onlyNumbers"
            onChangeControl={handleControls}
            helperText={workinDaysHelpText}
          />
        </Grid>
      </Grid>
      <SliderInput
        id="avgCustomer"
        label="Average customer price (BU CREW)​"
        value={controlsValue.avgCustomer}
        min={80}
        max={150}
        valueMask="€ {x}/h"
        onChangeControl={handleControls}
      />
      <SliderInput
        id="utilization"
        label="Utilization of the team​"
        value={controlsValue.utilization}
        valueMask="{x}%"
        onChangeControl={handleControls}
      />
      <TextInput
        id="othersCosts"
        label="Other business costs​"
        value={controlsValue.othersCosts.toString()}
        variation="currency"
        onChangeControl={handleControls}
      />
    </>
  );
};

export default Controls;
