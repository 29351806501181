export const fixedVariables = {
  hoursPerDay: { value: 8, label: "Hours per day", withMask: "8h" },
  avgBusinessCostPerPeson: {
    value: 6060,
    label: "Avg. total business cost  per person",
    withMask: "€6.060,00",
  },
  subRevenue: {
    value: 192000,
    label: "Subcontracting revenue",
    withMask: "€192.000,00",
  },
  subProfitMargin: {
    value: 0.201,
    label: "Subcontracting profit margin",
    withMask: "20,1%",
  },
  partTimeFte: { value: 3, label: "Part time effect in FTE", withMask: "3" },
  absenceFte: { value: 3.3, label: "Absence effect in FTE", withMask: "3,3" },
};

export const workingDaysForecast = [
  { month: "Jan", value: 22 },
  { month: "Feb", value: 20 },
  { month: "Mar", value: 23 },
  { month: "Apr", value: 18 },
  { month: "May", value: 20 },
  { month: "Jun", value: 22 },
  { month: "Jul", value: 21 },
  { month: "Aug", value: 23 },
  { month: "Sep", value: 21 },
  { month: "Oct", value: 20 },
  { month: "Nov", value: 22 },
  { month: "Dec", value: 19 },
];
