import { CardContent, Typography, Grid } from "@mui/material";

import { fixedVariables } from "../../../../../constants";

const FixedCollapse = () => {
  return (
    <CardContent>
      <Grid container>
        <Grid item xs={7}>
          {Object.entries(fixedVariables).map((item) => (
            <Typography variant="caption" display="block">
              {item[1].label}
            </Typography>
          ))}
        </Grid>
        <Grid item xs textAlign="end">
          {Object.entries(fixedVariables).map((item) => (
            <Typography variant="caption" display="block">
              {item[1].withMask}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default FixedCollapse;
