import { ReactNode } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Tooltip } from "@mui/material";

import * as S from "./styles";

type LabelProps = {
  children: string;
  htmlFor: string;
  helperText?: ReactNode;
};

const Label = ({ children, htmlFor, helperText }: LabelProps) => {
  return (
    <S.CustomLabel htmlFor={htmlFor}>
      {children}
      {helperText && (
        <Tooltip title={helperText} placement="right">
          <Box pl={1} display="inline">
            <HelpOutlineIcon fontSize="small" />
          </Box>
        </Tooltip>
      )}
    </S.CustomLabel>
  );
};

export default Label;
