import { forwardRef } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

type NumericCustomProps = {
  onChange: (event: { target: { value: string } }) => void;
};

const CurrencyInput = forwardRef<NumericFormatProps, NumericCustomProps>(
  ({ onChange, ...other }, ref) => (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      decimalScale={2}
      prefix="€"
    />
  )
);

export default CurrencyInput;
